import React from 'react'
import { Box, Typography } from '@material-ui/core'

const PermissionDenied = () => {
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="90vh"
      >
        <Typography variant="h3" component="h3">
          Permission Denied
        </Typography>
      </Box>
    </>
  )
}

export default PermissionDenied
