import React from 'react'
import Box from '@material-ui/core/Box'
import CandidateCustomers from './components/CandidateCustomers'
import SelectedTransactions from './components/SelectedTransactions'

const MoveRecords = () => {
  return (
    <>
      <Box m={4}>
        <SelectedTransactions />
      </Box>
      <Box m={4}>
        <CandidateCustomers />
      </Box>
    </>
  )
}

export default MoveRecords
