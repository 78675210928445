import { ReactElement } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { useSelector } from 'src/store'
import { RootState } from '../store'

interface PrivateRouteProps extends RouteProps {
  Component: (props: any) => ReactElement
  roleNeeded: string[]
}

const PrivateRoute = ({
  Component,
  roleNeeded,
  ...rest
}: PrivateRouteProps) => {
  const user = useSelector((state) => state.authentication.user)
  const loading = useSelector(
    (state: RootState) => state.authentication.authLoading
  )

  return (
    <Route
      {...rest}
      render={(props) =>
        loading ? (
          <></>
        ) : user === null ? (
          <Redirect to="/" />
        ) : (
          [
            roleNeeded.includes(user.role) ? (
              <Component {...props} />
            ) : (
              <Redirect to="/denied" />
            ),
          ]
        )
      }
    />
  )
}

export default PrivateRoute
