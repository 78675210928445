import { GridColDef, GridSortDirection } from '@mui/x-data-grid'

export interface ColumnDefinition extends GridColDef {
  title: string
}

export interface SearchParams {
  pageIndex: number
  pageSize: number
  searchValue: string
  appliedFilters: any[]
  order: GridSortDirection
  orderBy: string
  customerID?: string | number
}

export interface AppliedFilter {
  id: string
  operator: string
  value: string | number
}

export const ROLES = {
  administrator: 'accountadmin',
  editor: 'editor',
  viewer: 'viewer',
}

export const INIT_FILTERS = { id: '', value: '', operator: 'Contains' }

export const atLeastAdministratorRole = [ROLES.administrator]

export const atLeastEditorRole = [ROLES.administrator, ROLES.editor]

export const atLeastViewerRole = [
  ROLES.administrator,
  ROLES.editor,
  ROLES.viewer,
]

export const MERGE_ACTION = 'MERGE'

export interface CustomerResponse {
  uuid: string
  guid: number
  membershipid: string
  firstname: string
  lastname: string
  dobdate: string
  sourcetable: string
  email?: string
  phonenumber: string
  confidencescore: number
  scv_id_orig: string
  pending_merge?: Record<string, any> | null
  previous_scv_ids?: number[]
}

export interface TransactionResponse {
  transactionid: number
  transactionamount: number
  transactiontype: string
  transactiondate: string
  email: string
  membershipid: string
  first_name: string
  last_name: string
}

export interface LogResponse {
  uuid: string
  batch: number
  from_: number
  to: number
  prev: number
  timestamp: string
  action: string
}

export interface UserResponse {
  login_name: string
  role: string
}
