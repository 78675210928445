import { GridSortDirection } from '@mui/x-data-grid'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { AppliedFilter, ColumnDefinition, LogResponse } from 'src/constants'

interface LogsState {
  columnDefinition: ColumnDefinition[]
  numberOfLogsDisplayed: number
  currentPageNumber: number
  rowsPerPage: number
  order: GridSortDirection
  orderBy: string
  selectedLogs: string[]
  csv: {
    isSelectedExporting: boolean
    data: string
  }
  results: {
    pages: Record<number, { loading: true; logs: [] }>
  }
  allLogs: Record<number, LogResponse>
  appliedFilters: AppliedFilter[]
}

const initialState: LogsState = {
  columnDefinition: [],
  numberOfLogsDisplayed: 0,
  currentPageNumber: 0,
  rowsPerPage: 25,
  order: 'desc',
  orderBy: 'timestamp',
  selectedLogs: [],
  csv: {
    isSelectedExporting: false,
    data: '',
  },
  results: {
    pages: {
      0: {
        loading: true,
        logs: [],
      },
    },
  },
  allLogs: {},
  appliedFilters: [],
}

const logsSlice = createSlice({
  name: 'logs',
  initialState,
  reducers: {
    setRowsPerPage: (state: LogsState, action: PayloadAction<number>) => {
      state.rowsPerPage = action.payload
    },
    setCurrentPageNumber: (state: LogsState, action: PayloadAction<number>) => {
      const pageNumber = action.payload

      state.currentPageNumber = pageNumber
      Object.assign(state.results.pages, {
        [pageNumber]: {
          loading: true,
          logs: [],
        },
      })
    },
    fetchAllLogsRequest: (state: LogsState, action: PayloadAction<number>) => {
      const pageNumber = action.payload

      state.currentPageNumber = pageNumber
      Object.assign(state.results.pages, {
        [pageNumber]: {
          loading: true,
          logs: [],
        },
      })
    },
    fetchAllLogsResponse: (
      state: LogsState,
      action: PayloadAction<{ data: LogResponse[]; total: number }>
    ) => {
      const pageNumber = state.currentPageNumber

      const logIds = action.payload.data.map((t) => t.uuid)
      const fetchedLogs = action.payload.data.reduce(
        (acc: Record<string, LogResponse>, log) => {
          acc[log.uuid] = log
          return acc
        },
        {}
      )

      const allLogs = {
        ...state.allLogs,
        ...fetchedLogs,
      }

      state.numberOfLogsDisplayed = action.payload.total
      state.allLogs = allLogs
      Object.assign(state.results.pages, {
        [pageNumber]: {
          loading: false,
          logs: logIds,
        },
      })
    },
    setAppliedFilters: (state: LogsState, action: PayloadAction<any[]>) => {
      state.appliedFilters = action.payload
    },
    setOrdering: (
      state: LogsState,
      action: PayloadAction<{ order: GridSortDirection; orderBy: string }>
    ) => {
      const isAsc =
        state.orderBy === action.payload.orderBy && state.order === 'asc'
      const order = isAsc ? 'desc' : 'asc'

      state.orderBy = action.payload.orderBy
      state.order = order
    },
    toggleSelectedLogs: (state: LogsState, action: PayloadAction<string[]>) => {
      state.selectedLogs = action.payload.length > 0 ? action.payload : []
    },
    toggleSelectedLogsInPage: (
      state: LogsState,
      action: PayloadAction<LogResponse[]>
    ) => {
      state.selectedLogs =
        action.payload.length > 0 ? action.payload.map((row) => row.uuid) : []
    },
    downloadSelectedCSVDataRequest: (state: LogsState) => {
      state.csv.isSelectedExporting = true
    },
    downloadSelectedCSVDataResponse: (
      state: LogsState,
      action: PayloadAction<string>
    ) => {
      Object.assign(state.csv, {
        isSelectedExporting: false,
        data: action.payload,
      })
    },
    setLogsColumnDefinition: (
      state: LogsState,
      action: PayloadAction<ColumnDefinition[]>
    ) => {
      state.columnDefinition = action.payload
    },
    resetCSVData: (state: LogsState) => {
      Object.assign(state.csv, {
        isSelectedExporting: false,
        data: '',
      })
    },
  },
})

export const {
  downloadSelectedCSVDataResponse,
  downloadSelectedCSVDataRequest,
  fetchAllLogsResponse,
  fetchAllLogsRequest,
  setAppliedFilters,
  toggleSelectedLogsInPage,
  setLogsColumnDefinition,
  resetCSVData,
  toggleSelectedLogs,
  setOrdering,
  setCurrentPageNumber,
  setRowsPerPage,
} = logsSlice.actions

export default logsSlice.reducer
