import { useCallback } from 'react'
import { resetCustomerSelection } from 'src/reducers/customers'
import { resetTransactionSelection } from 'src/reducers/transactions'
import { useDispatch } from 'src/store'

export const useResetAllSelections = () => {
  const dispatch = useDispatch()

  return useCallback(() => {
    dispatch(resetCustomerSelection())
    dispatch(resetTransactionSelection())
  }, [dispatch])
}
