import React from 'react'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import Field from './Field'
import Select from './Select'

const useStyles = makeStyles((theme) => ({
  conditionalCard: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  cardHeader: {
    padding: 0,
  },
  closeButton: {
    padding: 0,
    marginTop: theme.spacing(),
  },
  selectForm: {
    width: '50%',
  },
  textFieldForm: {
    width: '100%',
  },
}))

const operators = [
  'Contains',
  'Is Equal To',
  'Starts With',
  'Ends With',
  '>=',
  '<=',
]

const ConditionalBlock = ({
  idx,
  fields,
  updateFilter,
  removeFilter,
  filter,
}) => {
  const classes = useStyles()

  return (
    <Card elevation={3} className={classes.conditionalCard}>
      <CardHeader
        className={classes.cardHeader}
        action={
          <IconButton
            className={classes.closeButton}
            onClick={() => removeFilter(idx)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
      <FormControl className={classes.selectForm}>
        <Select
          name="id"
          label="Field Name"
          value={filter.id}
          handleChange={(e) => updateFilter(idx, e)}
          options={fields}
        />
      </FormControl>
      <FormControl className={classes.selectForm}>
        <Select
          name="operator"
          label="Operator"
          value={filter.operator}
          handleChange={(e) => updateFilter(idx, e)}
          options={operators}
        />
      </FormControl>

      <FormControl className={classes.textFieldForm}>
        <Field
          name="value"
          label="Value"
          value={filter.value}
          handleChange={(e) => updateFilter(idx, e)}
        />
      </FormControl>
    </Card>
  )
}

export default ConditionalBlock
