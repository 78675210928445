import { Button, ButtonProps } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'

interface LoadingButtonProps extends ButtonProps {
  loading: boolean
  loadingText: string
  defaultText: string
}

const LoadingButton = ({
  loading,
  loadingText,
  defaultText,
  ...rest
}: LoadingButtonProps) => {
  return (
    <Button
      className={rest.className}
      variant="contained"
      onClick={rest.onClick}
      disabled={rest.disabled || loading}
      startIcon={loading && <CircularProgress size={20} />}
    >
      {loading ? <>{loadingText}</> : <>{defaultText}</>}
    </Button>
  )
}

LoadingButton.defaultProps = {
  className: undefined,
  disabled: false,
}

export default LoadingButton
