import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  IconButton,
  Paper,
  Typography,
  makeStyles,
} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { DataGrid, GridColDef, GridRowData } from '@mui/x-data-grid'
import { useMergeSelectedCustomers } from 'src/actions/customers'
import { EnhancedDataGridToolbar } from 'src/components/EnhancedDataGridToolbar'
import { CustomerResponse } from 'src/constants'
import {
  closeModal,
  openModal,
  removeForMerging,
  setAsPrimary,
  setModalType,
  toggleSelectCustomersInPage,
} from 'src/reducers/customers'
import { useDispatch, useSelector } from 'src/store'
import LoadingButton from '../../components/LoadingButton'
import { ConfirmationPopUp, SuccessfulPopUp } from './components/MergingPopUp'

interface PrimaryButtonProps {
  className: string
  disabled: boolean
}

const PrimaryButton = ({ className, disabled }: PrimaryButtonProps) => (
  <Button className={className} variant="contained" disabled={disabled}>
    Primary
  </Button>
)

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  nextIcon: {
    color: theme.palette.secondary.dark,
  },
  remove: {
    display: 'table-cell',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  removeIcon: {
    cursor: 'pointer',
    color: theme.palette.secondary.dark,
  },
  setButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontWeight: 'bold',
    textTransform: 'inherit',
  },
  primary: {
    fontWeight: 'bold',
    textTransform: 'inherit',
    '&.Mui-disabled': {
      background: theme.palette.info.main,
    },
  },
  setAsPrimary: {
    fontWeight: 'bold',
    textTransform: 'inherit',
  },
  primaryRow: {
    background: 'rgba(24,210,251, 0.2)',
  },
  mergeButton: {
    float: 'right',
    marginTop: 15,
    paddingLeft: 30,
    paddingRight: 30,
    fontWeight: 'bold',
    textTransform: 'inherit',
  },
  centerCell: {
    display: 'flex',
    placeItems: 'center',
    placeContent: 'center',
  },
}))

const MergeUsers = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { type } = useSelector((state) => state.customers.modal)
  const areCustomersMerging = useSelector(
    (state) => state.customers.areCustomersMerging
  )
  const forMerging = useSelector((state) => state.customers.forMerging)
  const selectedCustomers = forMerging.map((customer) => customer.uuid)

  const columnDefinition = useSelector(
    (state) => state.customers.columnDefinition
  )

  const mergeSelectedCustomers = useMergeSelectedCustomers()

  const columns: GridColDef[] = [
    {
      field: 'removeFromMerge',
      headerName: 'Remove from Merge',
      flex: 1 / 3,
      sortable: false,
      renderHeader: () => <></>,
      renderCell: ({ row }) => (
        <div className={classes.centerCell}>
          <IconButton
            disabled={areCustomersMerging}
            size="small"
            edge="start"
            onClick={(e) => {
              e.stopPropagation()
              dispatch(removeForMerging(row.uuid))
            }}
          >
            <ClearIcon className={classes.removeIcon} />
          </IconButton>
        </div>
      ),
    },
    ...columnDefinition.map((col) => ({
      ...col,
      headerName: col.title,
      flex: 1,
      sortable: false,
      hide: col.field === 'uuid',
      valueFormatter: ({ value }: GridRowData) =>
        col.field === 'confidencescore' ? value.toFixed(2) : value,
    })),
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      sortable: false,
      renderHeader: () => <></>,
      renderCell: ({ row }) => (
        <div className={classes.setButton}>
          {row.uuid === forMerging[0]?.uuid ? (
            <PrimaryButton disabled className={classes.primary} />
          ) : (
            <Button
              className={classes.setAsPrimary}
              variant="contained"
              disabled={areCustomersMerging}
              onClick={() => dispatch(setAsPrimary(row as CustomerResponse))}
            >
              Set as Primary
            </Button>
          )}
        </div>
      ),
    },
    {
      field: 'goToCustomerDetail',
      headerName: 'Go To Customer',
      flex: 1 / 3,
      sortable: false,
      renderHeader: () => <></>,
      renderCell: ({ row }) => (
        <div className={classes.centerCell}>
          <Link to={`/customer/${row.uuid}`}>
            <IconButton size="small" edge="end">
              <NavigateNextIcon className={classes.nextIcon} />
            </IconButton>
          </Link>
        </div>
      ),
    },
  ]

  const handleMergeRecords = () => {
    dispatch(closeModal())
    mergeSelectedCustomers(selectedCustomers)
      .then(() => {
        dispatch(openModal())
        dispatch(setModalType('success'))
        dispatch(toggleSelectCustomersInPage([]))
      })
      .catch(() => {
        toast.error('An error occured in merging the selected customers.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
        dispatch(closeModal())
        dispatch(setModalType('confirmation'))
      })
  }

  return (
    <>
      <Box>
        <Grid container>
          <Grid item md={8} xs={12}>
            <Box ml={4} mt={4}>
              <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
                <Link to="/">Main</Link>
                <Typography color="textPrimary">
                  Merge List ({forMerging.length})
                </Typography>
              </Breadcrumbs>
            </Box>
          </Grid>
        </Grid>
        <Box m={4}>
          <Paper className={classes.paper}>
            <div className={classes.tableWrapper}>
              <DataGrid
                autoHeight
                disableSelectionOnClick
                disableColumnMenu
                columns={columns}
                rows={forMerging}
                getRowId={(row) => row.uuid}
                components={{
                  Toolbar: () => (
                    <EnhancedDataGridToolbar
                      name="Selected Customers for Merging"
                      disableAllButtons
                    />
                  ),
                }}
              />
            </div>
          </Paper>
          <LoadingButton
            className={classes.mergeButton}
            loading={areCustomersMerging}
            disabled={forMerging.length < 2 || areCustomersMerging}
            loadingText="Merging..."
            defaultText="Merge"
            onClick={() => dispatch(openModal())}
          />
        </Box>
      </Box>
      {type === 'confirmation' ? (
        <ConfirmationPopUp handleMergeRecords={handleMergeRecords} />
      ) : type === 'success' ? (
        <SuccessfulPopUp />
      ) : (
        <></>
      )}
    </>
  )
}

export default MergeUsers
