import { useState } from 'react'
import { Box, Button, Link, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { API_URL } from 'src/actions/constants'

const useStyles = makeStyles((theme) => ({
  loginContainer: {
    padding: theme.spacing(3),
    width: '350px',
    textAlign: 'center',
  },
  fieldContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  errorMessage: {
    marginTop: theme.spacing(2),
    color: 'red',
  },
  successMessage: {
    marginTop: theme.spacing(2),
    color: 'green',
  },
  clickable: {
    cursor: 'pointer',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

const Login = () => {
  const classes = useStyles()

  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="90vh"
      >
        <Paper elevation={0} className={classes.loginContainer}>
          <Typography variant="h3" component="h3">
            TM SCV
          </Typography>
          {successMessage && (
            <Typography className={classes.successMessage}>
              {successMessage}
            </Typography>
          )}
          {errorMessage && (
            <Typography className={classes.errorMessage}>
              {errorMessage}
            </Typography>
          )}
          <div className={classes.fieldContainer}>
            <Link component="a" href={`${API_URL}auth`}>
              <Button color="primary" variant="contained">
                Login with Snowflake
              </Button>
            </Link>
          </div>
        </Paper>
      </Box>
    </>
  )
}

export default Login
