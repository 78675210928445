import { Grid } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { lighten, makeStyles } from '@material-ui/core/styles'
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid'

interface EnhancedDataGridToolbarProps {
  name: string
  numberSelected: number
  disableAllButtons: boolean
}

const useToolbarStyles = makeStyles((theme) => ({
  padding: {
    padding: theme.spacing(1.5),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
}))

const EnhancedDataGridToolbar = ({
  name,
  numberSelected,
  disableAllButtons,
}: EnhancedDataGridToolbarProps) => {
  const classes = useToolbarStyles()

  return (
    <GridToolbarContainer
      className={numberSelected > 0 ? classes.highlight : undefined}
    >
      <Grid container spacing={1} className={classes.padding}>
        <Grid item xs={12}>
          {numberSelected > 0 ? (
            <Typography color="inherit" variant="h6">
              {numberSelected} selected
            </Typography>
          ) : (
            <Typography variant="h6" id="tableTitle">
              {name}
            </Typography>
          )}
        </Grid>
        {!disableAllButtons && (
          <Grid item xs={12}>
            <GridToolbarColumnsButton />
            <GridToolbarDensitySelector />
          </Grid>
        )}
      </Grid>
    </GridToolbarContainer>
  )
}

EnhancedDataGridToolbar.defaultProps = {
  disableAllButtons: false,
  numberSelected: 0,
}

export { EnhancedDataGridToolbar }
