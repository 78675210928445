import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Box, Button, Grid, Modal, Paper, makeStyles } from '@material-ui/core'
import {
  closeModal,
  setForMerging,
  setModalType,
} from '../../../reducers/customers'

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: 'bold',
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalTitle: {
    textAlign: 'center',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: 20,
  },
  modalContent: {
    maxWidth: 450,
  },
  actionButton: {
    paddingLeft: 60,
    paddingRight: 60,
    fontWeight: 'bold',
    background: theme.palette.primary.main,
    color: 'white',
  },
  successTitle: {
    textAlign: 'center',
    color: theme.palette.success.main,
    fontWeight: 'bold',
    fontSize: 20,
  },
  progress: {
    marginTop: 20,
    width: 300,
  },
}))

const MergingPopUp = ({ onClose, children }) => {
  const classes = useStyles()
  const { isOpen } = useSelector((state) => state.customers.modal)
  return (
    <Modal className={classes.modal} open={isOpen} onClose={onClose}>
      <Paper elevation={0}>
        <Box p={3}>{children}</Box>
      </Paper>
    </Modal>
  )
}

export const ConfirmationPopUp = ({ handleMergeRecords }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const forMerging = useSelector((state) => state.customers.forMerging)

  const primary = forMerging[0]?.uuid
  const otherIds = forMerging
    .slice(1)
    .map((f) => f.uuid)
    .join(', ')

  return (
    <MergingPopUp onClose={() => dispatch(closeModal())}>
      <div className={classes.modalTitle}>Confirm merging?</div>

      <p className={classes.modalContent}>
        The selected customer records with SCV IDs:{' '}
        <span className={classes.bold}>{otherIds}</span> will be merged to{' '}
        <span className={classes.bold}>SCV ID Primary {primary}</span>. Do you
        wish to proceed?
      </p>

      <Grid container spacing={8} alignItems="center" justifyContent="center">
        <Grid item>
          <ActionButton onClick={handleMergeRecords}>Yes</ActionButton>
        </Grid>
        <Grid item>
          <ActionButton onClick={() => dispatch(closeModal())}>No</ActionButton>
        </Grid>
      </Grid>
    </MergingPopUp>
  )
}

export const SuccessfulPopUp = () => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const forMerging = useSelector((state) => state.customers.forMerging)
  const selectedCustomers = forMerging?.map((f) => f.uuid)
  const goToPrimary = () => {
    history.push(`/customer/${selectedCustomers[0]}`)
    dispatch(setForMerging([]))
    dispatch(closeModal())
    dispatch(setModalType('confirmation'))
  }
  return (
    <MergingPopUp onClose={goToPrimary}>
      <div className={classes.successTitle}>Merging successful!</div>

      <p className={classes.modalContent}>
        The selected customer records have been merged successfully. Changes to
        the records will be applied during the next SCV batch processing.
      </p>
      <Grid container alignItems="center" justifyContent="center">
        <ActionButton onClick={goToPrimary}>Proceed</ActionButton>
      </Grid>
    </MergingPopUp>
  )
}

const ActionButton = ({ onClick, children }) => {
  const classes = useStyles()
  return (
    <Button
      className={classes.actionButton}
      onClick={onClick}
      variant="contained"
    >
      {children}
    </Button>
  )
}
