import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  makeStyles,
} from '@material-ui/core'
import {
  DataGrid,
  GridColDef,
  GridRowData,
  GridSortModel,
} from '@mui/x-data-grid'
import { browserDownload } from 'src/actions/constants'
import { useUndoMergeCustomers } from 'src/actions/customers'
import { useFetchTransactions } from 'src/actions/transactions'
import { EnhancedDataGridToolbar } from 'src/components/EnhancedDataGridToolbar'
import LoadingButton from 'src/components/LoadingButton'
import { TransactionResponse, atLeastEditorRole } from 'src/constants'
import { toggleAreCustomersUnmerging } from 'src/reducers/customers'
import {
  setCurrentPageNumber,
  setOrdering,
  setRowsPerPage,
  toggleSelectTransactionsInPage,
} from 'src/reducers/transactions'
import { useDispatch, useSelector } from 'src/store'
import EnhancedDataGridLoadingOverlay from '../../../components/EnhancedDataGridLoadingOverlay'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}))

interface TransactionsProps {
  customerID: string
}

const Transactions = ({ customerID }: TransactionsProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  const fetchTransactions = useFetchTransactions()
  const undoMergeCustomers = useUndoMergeCustomers()

  const [undoMergeDialogOpen, setUndoMergeDialogOpen] = useState(false)

  const user = useSelector((state) => state.authentication.user)

  const allCustomers = useSelector((state) => state.customers.allCustomers)
  const customerDetails = allCustomers[customerID]

  const areCustomersUnmerging = useSelector(
    (state) => state.customers.areCustomersUnmerging
  )
  const pendingMerge = customerDetails?.pending_merge

  const columnDefinition = useSelector(
    (state) => state.transactions.columnDefinition
  )
  const searchValue = useSelector((state) => state.transactions.searchValue)
  const rowsPerPage = useSelector((state) => state.transactions.rowsPerPage)
  const selectedTransactions = useSelector(
    (state) => state.transactions.selectedTransactions
  )
  const numberOfTransactionsDisplayed = useSelector(
    (state) => state.transactions.numberOfTransactionsDisplayed
  )
  const page = useSelector((state) => state.transactions.currentPageNumber)

  const order = useSelector((state) => state.transactions.order)
  const orderBy = useSelector((state) => state.transactions.orderBy)
  const sortModel = [{ field: orderBy, sort: order }]

  const appliedFilters = useSelector(
    (state) => state.transactions.appliedFilters
  )
  const loading = useSelector(
    (state) => state.transactions.results.pages[page].loading
  )
  const transactionIds = useSelector(
    (state) => state.transactions.results.pages[page].transactions
  )
  const allTransactions = useSelector(
    (state) => state.transactions.allTransactions
  )
  const transactions = transactionIds.map((id) => allTransactions[id])

  const columns: GridColDef[] = [
    ...columnDefinition.map((col) => ({
      ...col,
      headerName: col.title,
      flex: 1,
      sortable: true,
      valueFormatter: ({ value }: GridRowData) => value || '-',
    })),
  ]

  const handleUndoMerge = async () => {
    const transaction_id = pendingMerge!.prev
    setUndoMergeDialogOpen(false)
    dispatch(toggleAreCustomersUnmerging())

    undoMergeCustomers({
      customer_id: customerDetails.uuid,
      transaction_id,
    })
      .then(() => {
        toast.success('Successfully unmerged customer', {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
        dispatchFetchTransactions()
      })
      .catch(() => {
        toast.error('Error unmerging customer', {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
      })
      .finally(() => {
        dispatch(toggleAreCustomersUnmerging())
      })
  }

  const handleUndoMergeClose = () => {
    setUndoMergeDialogOpen(false)
  }

  const handleDownloadSelectedRecords = () => {
    if (selectedTransactions.length === 0) {
      return toast.error('No records selected.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    }

    const params = new URLSearchParams()
    selectedTransactions.forEach((selectedTransaction) =>
      params.append('selectedTransactions', `${selectedTransaction}`)
    )

    const url =
      `/customers/${customerID}/transactions/download_selected?` +
      params.toString()
    browserDownload(url, 'transactions.csv')
  }

  const handleMoveTransactions = () => {
    if (selectedTransactions.length === 0) {
      return toast.error('No records selected.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    }

    return history.push(`/customer/${customerID}/transactions/move`)
  }

  const handleDownloadAllFilteredTransactions = () => {
    if (appliedFilters.length === 0) {
      return toast.error('No filters applied.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    }

    const params = new URLSearchParams()
    appliedFilters.forEach((filter) => {
      params.append('appliedFilters', JSON.stringify(filter))
    })
    params.append('searchValue', searchValue)
    params.append('order', `${order}`)
    params.append('orderBy', orderBy)

    const url =
      `/customers/${customerID}/transactions/download_filtered?` +
      params.toString()
    browserDownload(url, 'transactions.csv')
  }

  const dispatchFetchTransactions = useCallback(() => {
    fetchTransactions({
      searchValue,
      pageIndex: page,
      pageSize: rowsPerPage,
      appliedFilters,
      customerID,
      order,
      orderBy,
    })
  }, [
    fetchTransactions,
    searchValue,
    page,
    rowsPerPage,
    appliedFilters,
    customerID,
    order,
    orderBy,
  ])

  const handleSortModelChange = (model: GridSortModel) => {
    if (model.length === 0) return
    if (model[0].sort !== order || model[0].field !== orderBy) {
      dispatch(
        setOrdering({
          order: model[0].sort,
          orderBy: model[0].field,
        })
      )
    }
  }

  useEffect(() => {
    dispatchFetchTransactions()
  }, [dispatchFetchTransactions])

  const UndoMergeButton = () =>
    !!pendingMerge ? (
      <LoadingButton
        loading={areCustomersUnmerging}
        loadingText="Undoing merge..."
        defaultText="Undo Merge"
        variant="contained"
        onClick={() => setUndoMergeDialogOpen(true)}
      />
    ) : (
      <></>
    )

  const UndoMergeDialog = () =>
    !!pendingMerge ? (
      <Dialog
        open={undoMergeDialogOpen}
        onClose={handleUndoMergeClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Undo Merge</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to undo the pending merge?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUndoMergeClose}>No</Button>
          <Button variant="contained" onClick={handleUndoMerge} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    ) : (
      <></>
    )

  return (
    <>
      <Box mr={4} mt={4}>
        <Grid
          container
          alignItems="flex-start"
          justifyContent="flex-end"
          direction="row"
        >
          <UndoMergeButton />
          <UndoMergeDialog />
          <Box ml={3}>
            <Button
              variant="contained"
              onClick={() => handleDownloadAllFilteredTransactions()}
            >
              Download Filtered Transactions
            </Button>
          </Box>
          <Box ml={3}>
            <Button
              variant="contained"
              onClick={() => handleDownloadSelectedRecords()}
            >
              Download Selected Transactions
            </Button>
          </Box>
          {atLeastEditorRole.includes(user.role) && (
            <Box ml={3}>
              <Button
                onClick={() => handleMoveTransactions()}
                variant="contained"
                disabled={!!pendingMerge}
              >
                Move Transaction
              </Button>
            </Box>
          )}
        </Grid>
      </Box>
      <Box m={4}>
        <Paper className={classes.paper}>
          <div className={classes.tableWrapper}>
            <DataGrid
              autoHeight
              checkboxSelection
              disableColumnMenu
              loading={loading}
              pageSize={rowsPerPage}
              onPageSizeChange={(newPageSize) =>
                dispatch(setRowsPerPage(newPageSize))
              }
              paginationMode="server"
              rowCount={numberOfTransactionsDisplayed}
              page={page}
              onPageChange={(newPage) =>
                dispatch(setCurrentPageNumber(newPage))
              }
              getRowId={(row) => (row as TransactionResponse).transactionid}
              columns={columns}
              rows={transactions}
              sortingMode="server"
              sortModel={sortModel}
              onSortModelChange={handleSortModelChange}
              selectionModel={selectedTransactions}
              onSelectionModelChange={(model) =>
                dispatch(toggleSelectTransactionsInPage(model as number[]))
              }
              components={{
                Toolbar: () => (
                  <EnhancedDataGridToolbar
                    name="Transactions"
                    numberSelected={selectedTransactions.length}
                  />
                ),
                LoadingOverlay: EnhancedDataGridLoadingOverlay,
              }}
            />
          </div>
        </Paper>
      </Box>
    </>
  )
}

export default Transactions
