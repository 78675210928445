import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ColumnDefinition, UserResponse } from 'src/constants'

interface UsersState {
  columnDefinition: ColumnDefinition[]
  currentPageNumber: number
  rowsPerPage: number
  numberOfUsersDisplayed: number
  results: {
    pages: Record<
      number,
      {
        loading: boolean
        users: any[]
      }
    >
  }
  user: {
    loading: boolean
  }
  allUsers: Record<string, any>
}

const initialState: UsersState = {
  columnDefinition: [],
  currentPageNumber: 0,
  rowsPerPage: 25,
  numberOfUsersDisplayed: 0,
  results: {
    pages: {
      0: {
        loading: true,
        users: [],
      },
    },
  },
  user: {
    loading: true,
  },
  allUsers: {},
}

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setCurrentPageNumber: (
      state: UsersState,
      action: PayloadAction<number>
    ) => {
      const pageNumber = action.payload
      state.currentPageNumber = pageNumber
      Object.assign(state.results.pages, {
        [pageNumber]: {
          loading: true,
          users: [],
        },
      })
    },
    setRowsPerPage: (state: UsersState, action: PayloadAction<number>) => {
      Object.assign(state, {
        currentPageNumber: 0,
        rowsPerPage: action.payload,
      })
    },
    fetchAllUsersRequest: (
      state: UsersState,
      action: PayloadAction<number>
    ) => {
      const pageNumber = action.payload
      state.currentPageNumber = pageNumber
      Object.assign(state.results.pages, {
        [pageNumber]: {
          loading: true,
          users: [],
        },
      })
    },
    fetchAllUsersResponse: (
      state: UsersState,
      action: PayloadAction<UserResponse[]>
    ) => {
      const pageNumber = state.currentPageNumber

      const userIds = action.payload.map((user) => user.login_name)
      const fetchedUsers = action.payload.reduce(
        (acc: Record<string, UserResponse>, user) => {
          acc[user.login_name] = user
          return acc
        },
        {}
      )

      const allUsers = {
        ...state.allUsers,
        ...fetchedUsers,
      }

      state.numberOfUsersDisplayed = action.payload.length
      state.allUsers = allUsers
      Object.assign(state.results.pages, {
        [pageNumber]: {
          loading: false,
          users: userIds,
        },
      })
    },
    fetchUserRequest: (state: UsersState) => {
      state.user.loading = false
    },
    setUsersColumnDefinition: (
      state: UsersState,
      action: PayloadAction<ColumnDefinition[]>
    ) => {
      state.columnDefinition = action.payload
    },
  },
})

export const {
  fetchAllUsersResponse,
  setUsersColumnDefinition,
  fetchUserRequest,
  fetchAllUsersRequest,
  setRowsPerPage,
  setCurrentPageNumber,
} = usersSlice.actions

export default usersSlice.reducer
