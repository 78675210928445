import { useState } from 'react'
import { useDispatch } from 'react-redux'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import produce from 'immer'
import { INIT_FILTERS } from '../constants'
import { resetSearchAndFilters as resetSearchAndFiltersForCustomers } from '../reducers/customers'
import { resetSearchAndFilters as resetSearchAndFiltersForTransactions } from '../reducers/transactions'
import ConditionalBlock from './ConditionalBlock'

const useStyles = makeStyles((theme) => ({
  filterFragment: {
    margin: theme.spacing(4),
  },
  filterContainer: {
    padding: theme.spacing(4),
    alignSelf: 'flex-start',
  },
  title: {
    fontWeight: 700,
    marginBottom: theme.spacing(),
  },
  conditionalsSection: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(3),
  },
  addFilterButton: {
    alignSelf: 'flex-end',
    padding: 0,
    fontWeight: 700,
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: theme.spacing(4),
  },
}))

const FilterSection = ({ fields, appliedFilters, applyFilters }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [filters, setFilters] = useState(
    appliedFilters.length > 0 ? appliedFilters : [INIT_FILTERS]
  )

  const addFilter = () =>
    setFilters((prevFilters) =>
      produce(prevFilters, (draft) => {
        draft.push(INIT_FILTERS)
      })
    )

  const updateFilter = (idx, { target: { name, value } }) =>
    setFilters((prevFilters) =>
      produce(prevFilters, (draft) => {
        draft[idx][name] = value
      })
    )

  const removeFilter = (idx) =>
    setFilters((prevFilters) =>
      produce(prevFilters, (draft) => {
        draft.splice(idx, 1)
      })
    )

  const resetFilters = () => {
    setFilters([INIT_FILTERS])
  }

  const resetTable = () => {
    if (window.location.pathname === '/') {
      dispatch(resetSearchAndFiltersForCustomers())
    } else {
      dispatch(resetSearchAndFiltersForTransactions())
    }
    resetFilters()
  }

  const handleSubmitFilters = (e) => {
    e.preventDefault()
    applyFilters(filters)
  }

  return (
    <>
      <Box mt={2}>
        <Paper elevation={4} className={classes.filterContainer}>
          <form onSubmit={handleSubmitFilters}>
            <Typography variant="h6" color="primary" className={classes.title}>
              Filters
            </Typography>
            <div className={classes.conditionalsSection}>
              {filters.map((filter, i) => (
                <ConditionalBlock
                  key={i}
                  idx={i}
                  fields={fields}
                  updateFilter={updateFilter}
                  removeFilter={removeFilter}
                  filter={filter}
                />
              ))}
              <Button
                color="primary"
                className={classes.addFilterButton}
                onClick={addFilter}
              >
                + Add conditional filter
              </Button>
            </div>

            <Box display="flex" mt={3}>
              <Box flexGrow={1}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={resetTable}
                  className={classes.actionButtons}
                >
                  Reset table
                </Button>
              </Box>
              <Box mr={1}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={resetFilters}
                  className={classes.actionButtons}
                >
                  Reset filters
                </Button>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className={classes.actionButtons}
                  onClick={handleSubmitFilters}
                >
                  Apply filters
                </Button>
              </Box>
            </Box>
          </form>
        </Paper>
      </Box>
    </>
  )
}

export default FilterSection
