import { useEffect } from 'react'
import { toast } from 'react-toastify'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { DataGrid, GridSortModel } from '@mui/x-data-grid'
import { GridColDef } from '@mui/x-data-grid'
import 'date-fns'
import { clone } from 'lodash-es'
import { useFetchAllLogs, useFetchLogColumnDefinition } from 'src/actions/logs'
import { EnhancedDataGridToolbar } from 'src/components/EnhancedDataGridToolbar'
import {
  setAppliedFilters,
  setCurrentPageNumber,
  setOrdering,
  setRowsPerPage,
  toggleSelectedLogs,
} from 'src/reducers/logs'
import { useDispatch, useSelector } from 'src/store'
import EnhancedDataGridLoadingOverlay from '../../components/EnhancedDataGridLoadingOverlay'
import FilterSection from '../../components/FilterSection'

const useStyles = makeStyles((theme) => ({
  filterBox: {
    padding: theme.spacing(4),
    marginTop: theme.spacing(1),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  filterButton: {
    marginTop: theme.spacing(2),
  },
}))

const ViewAllLogs = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const page = useSelector((state) => state.logs.currentPageNumber)
  const selectedLogs = useSelector((state) => state.logs.selectedLogs)
  const loading = useSelector((state) => state.logs.results.pages[page].loading)
  const rowsPerPage = useSelector((state) => state.logs.rowsPerPage)
  const totalNumberOfLogs = useSelector(
    (state) => state.logs.numberOfLogsDisplayed
  )
  const logIds = useSelector((state) => state.logs.results.pages[page].logs)
  const allLogs = useSelector((state) => state.logs.allLogs)
  const logs = logIds.map((id) => allLogs[id])
  const appliedFilters = useSelector((state) => state.logs.appliedFilters)

  const order = useSelector((state) => state.logs.order)
  const orderBy = useSelector((state) => state.logs.orderBy)
  const sortModel = [{ field: orderBy, sort: order }]

  const columnDefinition = useSelector((state) => state.logs.columnDefinition)
  const filterFields = columnDefinition.map((col) => col.title)

  const columns: GridColDef[] = [
    ...columnDefinition.map((col) => ({
      ...col,
      headerName: col.title,
      flex: 1,
      hide: col.field === 'uuid',
    })),
  ]

  const fetchAllLogs = useFetchAllLogs()
  const fetchLogColumnDefinition = useFetchLogColumnDefinition()

  useEffect(() => {
    fetchAllLogs({
      pageIndex: page,
      pageSize: rowsPerPage,
      searchValue: '',
      appliedFilters,
      order,
      orderBy,
    })
  }, [dispatch, page, rowsPerPage, appliedFilters, order, orderBy])

  const applyFilters = (filters: any[]) => {
    if (filters.length > 0) {
      let isValid = true
      filters.forEach((filter) => {
        if (filter.id === '') {
          isValid = false
        }
      })

      if (isValid) {
        dispatch(setAppliedFilters(clone(filters)))
        return
      }
    }

    toast.error('Missing inputs in filter box.', {
      position: toast.POSITION.BOTTOM_RIGHT,
    })
  }

  // TODO: Implement download logs
  // const handleDownloadSelectedLogs = () => {
  //   if (selectedLogs.length === 0) {
  //     return toast.error('No logs selected.', {
  //       position: toast.POSITION.BOTTOM_RIGHT,
  //     })
  //   }
  //
  //   const params = new URLSearchParams()
  //   selectedLogs.forEach((selectedLog) =>
  //     params.append('selectedLogs', selectedLog)
  //   )
  //   params.append('token', token)
  //
  //   history.push(`/logs/download?${params.toString()}`)
  // }

  const handleSortModelChange = (model: GridSortModel) => {
    if (model.length === 0) return
    if (model[0].sort !== order || model[0].field !== orderBy) {
      dispatch(
        setOrdering({
          order: model[0].sort,
          orderBy: model[0].field,
        })
      )
    }
  }

  useEffect(() => {
    fetchLogColumnDefinition()
  }, [dispatch])

  return (
    <>
      <Box m={4}>
        <Box mb={2}>
          <Typography variant="h3" component="h3">
            Changelog
          </Typography>
        </Box>
        <Grid container>
          <Grid item md={4} xs={12}>
            <Box px={4}>
              <FilterSection
                applyFilters={applyFilters}
                fields={filterFields}
                appliedFilters={appliedFilters}
              />
            </Box>
          </Grid>
          <Grid item md={8} xs={12}>
            {/* TODO: Implement download in backend */}
            {/* <Box display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                onClick={() => handleDownloadSelectedLogs()}
              >
                Download Selected Logs
              </Button>
            </Box> */}

            <Paper className={classes.paper}>
              <div className={classes.tableWrapper}>
                <DataGrid
                  autoHeight
                  checkboxSelection
                  disableColumnMenu
                  loading={loading}
                  pageSize={rowsPerPage}
                  onPageSizeChange={(newPageSize) =>
                    dispatch(setRowsPerPage(newPageSize))
                  }
                  paginationMode="server"
                  rowCount={totalNumberOfLogs}
                  page={page}
                  onPageChange={(newPage) =>
                    dispatch(setCurrentPageNumber(newPage))
                  }
                  columns={columns}
                  rows={logs}
                  getRowId={(row) => row.uuid}
                  sortingMode="server"
                  sortModel={sortModel}
                  onSortModelChange={handleSortModelChange}
                  selectionModel={selectedLogs}
                  onSelectionModelChange={(model) =>
                    dispatch(toggleSelectedLogs(model as string[]))
                  }
                  components={{
                    Toolbar: () => (
                      <EnhancedDataGridToolbar
                        name="Logs"
                        numberSelected={selectedLogs.length}
                      />
                    ),
                    LoadingOverlay: EnhancedDataGridLoadingOverlay,
                  }}
                />
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default ViewAllLogs
