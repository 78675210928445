import axios, { AxiosError, AxiosResponse, HttpStatusCode } from 'axios'
import download from 'downloadjs'

let REACT_APP_API_URL = process.env.REACT_APP_API_URL || '/'

if (!REACT_APP_API_URL.endsWith('/')) {
  REACT_APP_API_URL = `${REACT_APP_API_URL}/`
}

const NODE_ENV = process.env.NODE_ENV || 'production'

export const API_URL = NODE_ENV === 'production' ? '/' : REACT_APP_API_URL

export const getAxios = () => {
  let axiosClient = axios.create({
    baseURL: API_URL,
    withCredentials: true,
  })

  const refreshUrl = '/auth/refresh'

  const onResponseFulfilled = (response: AxiosResponse) => response

  const onResponseRejected = async (error: AxiosError) => {
    const { status } = error.response!
    const originalRequest = error.config!

    if (axios.isCancel(error)) {
      return Promise.reject(error)
    } else if (
      status === HttpStatusCode.Unauthorized &&
      originalRequest.url !== refreshUrl
    ) {
      try {
        await axiosClient.get(refreshUrl)
        try {
          let res = await axiosClient.request(originalRequest)
          return Promise.resolve(res)
        } catch (err) {
          return Promise.reject(err)
        }
      } catch (err) {
        try {
          await axiosClient.get('/auth/logout')
        } finally {
          // pass
        }
      }
    } else {
      return Promise.reject(error)
    }
  }

  axiosClient.interceptors.response.use(onResponseFulfilled, onResponseRejected)

  return axiosClient
}

export const browserDownload = (url: string, filename: string) => {
  return getAxios()
    .get(url)
    .then((response) => {
      download(response.data, filename, response.headers['content-type'])
    })
}
