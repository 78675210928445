import { Suspense, useCallback, useEffect } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { PersistGate } from 'redux-persist/integration/react'
import { login, logout } from 'src/actions/authentication'
import FullPageLoading from 'src/components/FullPageLoading'
import Header from 'src/components/Header'
import {
  atLeastAdministratorRole,
  atLeastEditorRole,
  atLeastViewerRole,
} from 'src/constants'
import Login from 'src/scenes/Login/Login'
import MainPage from 'src/scenes/MainPage/MainPage'
import MergeUsers from 'src/scenes/MergeUsers/MergeUsers'
import ViewAllUsers from 'src/scenes/ViewAllUsers/ViewAllUsers'
import ViewCustomer from 'src/scenes/ViewCustomer/ViewCustomer'
import ViewLogs from 'src/scenes/ViewLogs/ViewLogs'
import PrivateRoute from './components/PrivateRoute'
import MoveRecords from './scenes/MoveRecords/MoveRecords'
import PermissionDenied from './scenes/PermissionDenied/PermissionDenied'
import { persistor, useDispatch, useSelector } from './store'

const CheckLoginUtil = () => {
  const dispatch = useDispatch()

  const checkIfLoggedIn = useCallback(() => {
    try {
      dispatch(login())
    } catch (error) {
      dispatch(logout())
    }
  }, [dispatch])

  useEffect(() => {
    checkIfLoggedIn()
  }, [checkIfLoggedIn])

  return <></>
}

const App = () => {
  const authState = useSelector((state) => state.authentication)

  return (
    <PersistGate loading={<FullPageLoading />} persistor={persistor}>
      <BrowserRouter>
        <ToastContainer />
        <Header />
        <CheckLoginUtil />
        <Switch>
          <Suspense fallback={<FullPageLoading />}>
            {authState.authLoading ? (
              <Route path="" component={FullPageLoading} />
            ) : authState.user == null ? (
              <Route path="/" component={Login} />
            ) : (
              <PrivateRoute
                path="/"
                exact
                Component={MainPage}
                roleNeeded={atLeastViewerRole}
              />
            )}
            <Route path="/denied" exact component={PermissionDenied} />
            <PrivateRoute
              path="/customer/:id"
              exact
              roleNeeded={atLeastViewerRole}
              Component={ViewCustomer}
            />
            <PrivateRoute
              path="/merge-list"
              exact
              roleNeeded={atLeastEditorRole}
              Component={MergeUsers}
            />
            <PrivateRoute
              path="/customer/:id/transactions/move"
              exact
              roleNeeded={atLeastEditorRole}
              Component={MoveRecords}
            />
            <PrivateRoute
              path="/users"
              exact
              roleNeeded={atLeastAdministratorRole}
              Component={ViewAllUsers}
            />
            <PrivateRoute
              path="/logs"
              exact
              roleNeeded={atLeastAdministratorRole}
              Component={ViewLogs}
            />
          </Suspense>
        </Switch>
      </BrowserRouter>
    </PersistGate>
  )
}

export default App
