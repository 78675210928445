import TextField, { StandardTextFieldProps } from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingRight: theme.spacing(2),
  },
  field: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  inputText: {
    fontSize: '0.875rem',
  },
}))

interface FieldProps extends StandardTextFieldProps {
  onKeyPress: (e: any) => void
  handleChange: (e: any) => void
  pattern?: any
  classes: ClassNameMap
}

const Field = ({
  name,
  label,
  value,
  type,
  placeholder,
  pattern,
  disabled,
  handleChange,
  onKeyPress,
}: FieldProps) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <TextField
        id={name}
        name={name}
        label={label}
        value={value}
        type={type === 'password' ? 'password' : 'text'}
        placeholder={placeholder}
        disabled={disabled}
        onChange={handleChange}
        onKeyPress={onKeyPress}
        className={classes.field}
        inputProps={{ pattern }}
        InputProps={{
          classes: {
            root: classes.inputText,
          },
        }}
        InputLabelProps={{
          classes: {
            root: classes.inputText,
          },
        }}
      />
    </div>
  )
}

export default Field
