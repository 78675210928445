import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Box, Grid, Paper, Typography, makeStyles } from '@material-ui/core'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { useDispatch, useSelector } from 'src/store'
import {
  useFetchCandidateCustomers,
  useMoveTransactionsToCustomer,
} from '../../../actions/transactions'
import EnhancedDataGridLoadingOverlay from '../../../components/EnhancedDataGridLoadingOverlay'
import LoadingButton from '../../../components/LoadingButton'
import { fetchCustomerRequest } from '../../../reducers/customers'
import { toggleSelectTransactionsInPage } from '../../../reducers/transactions'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(4),
  },
}))

const CandidateCustomers = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()

  const fetchCandidateCustomers = useFetchCandidateCustomers()
  const moveTransactionsToCustomer = useMoveTransactionsToCustomer()

  const customerID = params.id

  const selectedTransactions = useSelector(
    (state) => state.transactions.selectedTransactions
  )
  const loading = useSelector(
    (state) => state.customers.candidateCustomers.loading
  )
  const candidateCustomersIds = useSelector(
    (state) => state.customers.candidateCustomers.customers
  )
  const allCustomers = useSelector((state) => state.customers.allCustomers)
  const candidateCustomers = candidateCustomersIds.map((id) => allCustomers[id])

  const isMovingTransactionsToCustomer = useSelector(
    (state) => state.customers.isMovingTransactionsToCustomer
  )

  const [selected, setSelected] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const columnDefinition = useSelector(
    (state) => state.customers.columnDefinition
  )

  /** @type GridColDef[] */
  const columns = [
    ...columnDefinition.map((col) => ({
      ...col,
      headerName: col.title,
      flex: 1,
      sortable: true,
      hide: col.field === 'uuid',
      valueFormatter: ({ value }) =>
        col.field === 'confidencescore' && !!value ? value.toFixed(2) : value,
    })),
  ]

  useEffect(() => {
    fetchCandidateCustomers({ selectedTransactions, customerID })
  }, [fetchCandidateCustomers])

  const handleMoveClick = () => {
    if (selected.length === 0) {
      return toast.error(
        'Please select the customer to move the transactions to.',
        {
          position: toast.POSITION.BOTTOM_RIGHT,
        }
      )
    }

    if (
      window.confirm(
        'Are you sure you want to move the selected transactions to the selected customer?'
      )
    ) {
      moveTransactionsToCustomer({
        selectedTransactions,
        selectedCustomerID: selected,
      })
        .then((response) => {
          if (response.status === 200) {
            dispatch(fetchCustomerRequest())
            history.push(`/customer/${response.data.selected_customer_id}`)
            alert('Successfully transferred transactions.')
            dispatch(toggleSelectTransactionsInPage([]))
          }
        })
        .catch(() => {
          toast.error('An error occured while moving the transactions.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          })
        })
    }
  }

  const handleChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage)
    setPage(0)
  }

  return (
    <>
      <Grid container justifyContent="space-between" direction="row">
        <Typography variant="h4" component="h4" gutterBottom>
          Candidate Golden Customers
        </Typography>
        <LoadingButton
          loading={isMovingTransactionsToCustomer}
          loadingText="Moving to selected customer..."
          defaultText="Move to Selected Customer"
          onClick={handleMoveClick}
        />
      </Grid>

      <Box mt={2}>
        <Paper className={classes.paper}>
          <div>
            <DataGrid
              autoHeight
              checkboxSelection
              disableColumnFilter
              loading={loading}
              rowsPerPageOptions={[10, 25, 50]}
              page={page}
              onPageChange={(newPage) => setPage(newPage)}
              pageSize={rowsPerPage}
              onPageSizeChange={handleChangeRowsPerPage}
              rowCount={candidateCustomers.length}
              getRowId={(row) => row.uuid}
              columns={columns}
              rows={candidateCustomers}
              selectionModel={selected}
              onSelectionModelChange={(selection) =>
                setSelected(selection.slice(-1)[0])
              }
              components={{
                LoadingOverlay: EnhancedDataGridLoadingOverlay,
              }}
            />
            {/* <Table>
              <EnhancedTableHead
                classes={classes}
                rowCount={candidateCustomers.length}
                headCells={customersHeadCells}
                hasCheckBox
              />
              <TableBody>
                {!loading ? (
                  candidateCustomers.length > 0 ? (
                    candidateCustomers
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        const isItemSelected = selected === row._id

                        return (
                          <TableRow hover tabIndex={-1} key={row._id}>
                            <TableCell
                              padding="checkbox"
                              onClick={() => setSelected(row._id)}
                            >
                              <Checkbox checked={isItemSelected} />
                            </TableCell>
                            {customersHeadCells.map((head, j) => (
                              <TableCell key={j}>{row[head.id]}</TableCell>
                            ))}
                          </TableRow>
                        )
                      })
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={Object.keys(customersHeadCells).length + 1}
                      >
                        No candidate customers available.
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={Object.keys(customersHeadCells).length + 1}
                    >
                      Loading...
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={candidateCustomers.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={(event, newPage) => setPage(newPage)}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            /> */}
          </div>
        </Paper>
      </Box>
    </>
  )
}

export default CandidateCustomers
