import { Box, LinearProgress } from '@material-ui/core'
import { GridOverlay } from '@mui/x-data-grid'

const EnhancedDataGridLoadingOverlay = () => (
  <GridOverlay>
    <Box
      component="div"
      style={{
        position: 'absolute',
        top: 0,
        width: '100%',
      }}
    >
      <LinearProgress />
    </Box>
  </GridOverlay>
)

export default EnhancedDataGridLoadingOverlay
