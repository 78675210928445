import { GridSortDirection } from '@mui/x-data-grid'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  AppliedFilter,
  ColumnDefinition,
  CustomerResponse,
} from 'src/constants'

interface CustomersState {
  columnDefinition: ColumnDefinition[]
  searchValue: string
  currentPageNumber: number
  rowsPerPage: number
  numberOfGoldenCustomersDisplayed: number
  order: GridSortDirection
  orderBy: string
  appliedFilters: AppliedFilter[]
  selectedCustomers: string[]
  areCustomersMerging: boolean
  areCustomersUnmerging: boolean
  results: {
    pages: Record<number, { loading: boolean; customers: string[] }>
  }
  customer: {
    loading: boolean
    pending: boolean
  }
  allCustomers: Record<string, CustomerResponse>
  candidateCustomers: {
    loading: true
    customers: []
  }
  isCreatingNewCustomer: boolean
  isMovingTransactionsToCustomer: boolean
  forMerging: CustomerResponse[]
  modal: {
    type: string
    isOpen: boolean
  }
  pending: boolean
}

const initialState: CustomersState = {
  columnDefinition: [],
  searchValue: '',
  currentPageNumber: 0,
  rowsPerPage: 25,
  numberOfGoldenCustomersDisplayed: 0,
  order: 'desc',
  orderBy: 'confidencescore',
  appliedFilters: [],
  selectedCustomers: [],
  areCustomersMerging: false,
  areCustomersUnmerging: false,
  results: {
    pages: {
      0: {
        loading: true,
        customers: [],
      },
    },
  },
  customer: {
    loading: true,
    pending: false,
  },
  allCustomers: {},
  candidateCustomers: {
    loading: true,
    customers: [],
  },
  isCreatingNewCustomer: false,
  isMovingTransactionsToCustomer: false,
  forMerging: [],
  modal: {
    type: 'confirmation',
    isOpen: false,
  },
  pending: false,
}

const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    setSearchValue: (state: CustomersState, action: PayloadAction<string>) => {
      Object.assign(state, {
        searchValue: action.payload,
        orderBy: 'confidencescore',
        order: 'desc',
        currentPageNumber: 0,
      })
    },
    setCurrentPageNumber: (
      state: CustomersState,
      action: PayloadAction<number>
    ) => {
      const pageNumber = action.payload

      state.currentPageNumber = pageNumber
      Object.assign(state.results.pages, {
        [pageNumber]: {
          loading: true,
          customers: [],
        },
      })
    },
    setOrdering: (
      state: CustomersState,
      action: PayloadAction<{ order: GridSortDirection; orderBy: string }>
    ) => {
      const isAsc =
        state.orderBy === action.payload.orderBy && state.order === 'asc'
      const order = isAsc ? 'desc' : 'asc'

      state.orderBy = action.payload.orderBy
      state.order = order
    },
    setRowsPerPage: (state: CustomersState, action: PayloadAction<number>) => {
      state.currentPageNumber = 0
      state.rowsPerPage = action.payload
    },
    setAppliedFilters: (
      state: CustomersState,
      action: PayloadAction<AppliedFilter[]>
    ) => {
      state.currentPageNumber = 0
      state.appliedFilters = action.payload
    },
    setForMerging: (
      state: CustomersState,
      action: PayloadAction<CustomerResponse[]>
    ) => {
      state.forMerging = action.payload
    },
    removeForMerging: (
      state: CustomersState,
      action: PayloadAction<string>
    ) => {
      const id = action.payload

      state.selectedCustomers = state.selectedCustomers.filter(
        (customerId) => customerId !== id
      )
      state.forMerging = state.forMerging.filter(
        (customer) => customer.uuid !== id
      )
    },
    setAsPrimary: (
      state: CustomersState,
      action: PayloadAction<CustomerResponse>
    ) => {
      const id = action.payload.uuid
      const newOrder = state.forMerging.filter(
        (customer) => customer.uuid !== id
      )
      newOrder.unshift(action.payload)

      state.forMerging = newOrder
    },
    openModal: (state: CustomersState) => {
      state.modal = { type: 'confirmation', isOpen: true }
    },
    closeModal: (state: CustomersState) => {
      state.modal.isOpen = false
    },
    setModalType: (state: CustomersState, action: PayloadAction<string>) => {
      state.modal.type = action.payload
    },
    toggleSelectedCustomers: (
      state: CustomersState,
      action: PayloadAction<string>
    ) => {
      const id = action.payload
      const selectedIndex = state.selectedCustomers.indexOf(id)

      if (selectedIndex === -1) {
        state.selectedCustomers = [...state.selectedCustomers, id]
        return
      }

      state.selectedCustomers = [
        ...state.selectedCustomers.slice(0, selectedIndex),
        ...state.selectedCustomers.slice(selectedIndex + 1),
      ]
    },
    setSelectedCustomers: (
      state: CustomersState,
      action: PayloadAction<string[]>
    ) => {
      state.selectedCustomers = action.payload
    },
    toggleSelectCustomersInPage: (
      state: CustomersState,
      action: PayloadAction<string[]>
    ) => {
      state.selectedCustomers = action.payload.length > 0 ? action.payload : []
    },
    toggleAreCustomersMerging: (state: CustomersState) => {
      state.areCustomersMerging = !state.areCustomersMerging
    },
    toggleAreCustomersUnmerging: (state: CustomersState) => {
      state.areCustomersUnmerging = !state.areCustomersUnmerging
    },
    fetchGoldenCustomersRequest: (
      state: CustomersState,
      action: PayloadAction<number>
    ) => {
      const pageNumber = action.payload

      state.currentPageNumber = pageNumber
      Object.assign(state.results.pages, {
        [pageNumber]: {
          ...state.results.pages[pageNumber],
          loading: true,
          customers: [],
        },
      })
    },
    fetchGoldenCustomersResponse: (
      state: CustomersState,
      action: PayloadAction<{
        data: { data: CustomerResponse[]; count: number }
        total_number_of_customers: number
      }>
    ) => {
      const pageNumber = state.currentPageNumber

      const customerIds = action.payload.data.data.map((t) => t.uuid)
      const fetchedCustomers = action.payload.data.data.reduce(
        (acc, customer) => {
          acc[customer.uuid] = customer
          return acc
        },
        {} as Record<string, CustomerResponse>
      )

      const allCustomers = {
        ...state.allCustomers,
        ...fetchedCustomers,
      }

      state.numberOfGoldenCustomersDisplayed =
        action.payload.total_number_of_customers
      Object.assign(state.results.pages, {
        [pageNumber]: {
          ...state.results.pages[pageNumber],
          loading: false,
          customers: customerIds,
        },
      })
      state.allCustomers = allCustomers
    },
    removePendingMerge: (
      state: CustomersState,
      action: PayloadAction<string>
    ) => {
      state.allCustomers[action.payload].pending_merge = null
    },
    fetchCustomerRequest: (state: CustomersState) => {
      state.customer.pending = state.pending
    },
    fetchCustomerResponse: (
      state: CustomersState,
      action: PayloadAction<{ data: CustomerResponse }>
    ) => {
      const fetchedCustomerID = action.payload.data.uuid
      const fetchedCustomer = {
        [fetchedCustomerID]: action.payload.data,
      }
      const allCustomers = {
        ...state.allCustomers,
        ...fetchedCustomer,
      }

      state.customer.loading = false
      state.allCustomers = allCustomers
    },
    fetchCandidateCustomersRequest: (state: CustomersState) => {
      state.candidateCustomers.loading = true
    },
    fetchCandidateCustomersResponse: (
      state: CustomersState,
      action: PayloadAction<{ data: CustomerResponse[] }>
    ) => {
      const customerIds = action.payload.data.map((c) => c.uuid)
      const fetchedCandidateCustomers = action.payload.data.reduce(
        (acc, customer) => {
          acc[customer.uuid] = customer
          return acc
        },
        {} as Record<string, CustomerResponse>
      )

      const allCustomers = {
        ...state.allCustomers,
        ...fetchedCandidateCustomers,
      }

      Object.assign(state.candidateCustomers, {
        loading: false,
        customers: customerIds,
      })
      state.allCustomers = allCustomers
    },
    toggleIsCreatingNewCustomer: (state: CustomersState) => {
      state.isCreatingNewCustomer = !state.isCreatingNewCustomer
    },
    toggleIsMovingTransactionsToCustomer: (state: CustomersState) => {
      state.isMovingTransactionsToCustomer =
        !state.isMovingTransactionsToCustomer
    },
    resetSearchAndFilters: (state: CustomersState) => {
      Object.assign(state, {
        searchValue: '',
        appliedFilters: [],
      })
    },
    setCustomersColumnDefinition: (
      state: CustomersState,
      action: PayloadAction<ColumnDefinition[]>
    ) => {
      state.columnDefinition = action.payload
    },
    resetCustomerSelection: (state: CustomersState) => {
      state.selectedCustomers = []
    },
  },
})

export const {
  fetchCandidateCustomersResponse,
  fetchCandidateCustomersRequest,
  fetchGoldenCustomersRequest,
  fetchGoldenCustomersResponse,
  fetchCustomerRequest,
  fetchCustomerResponse,
  setCustomersColumnDefinition,
  setSelectedCustomers,
  toggleSelectedCustomers,
  toggleSelectCustomersInPage,
  toggleAreCustomersMerging,
  toggleAreCustomersUnmerging,
  toggleIsCreatingNewCustomer,
  toggleIsMovingTransactionsToCustomer,
  removeForMerging,
  removePendingMerge,
  setForMerging,
  closeModal,
  openModal,
  setModalType,
  resetSearchAndFilters,
  setAppliedFilters,
  setAsPrimary,
  setSearchValue,
  setOrdering,
  setRowsPerPage,
  setCurrentPageNumber,
  resetCustomerSelection,
} = customersSlice.actions

export default customersSlice.reducer
