import { useEffect, useState } from 'react'
import { Link } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { DataGrid } from '@mui/x-data-grid'
import { GridColDef } from '@mui/x-data-grid'
import { API_URL } from 'src/actions/constants'
import {
  useFetchAllUsers,
  useFetchUserColumnDefinition,
} from 'src/actions/users'
import { useSelector } from 'src/store'
import EnhancedDataGridLoadingOverlay from '../../components/EnhancedDataGridLoadingOverlay'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  actionButtonCell: {
    '& > *': {
      marginRight: theme.spacing(2),
    },
  },
  deleteButton: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  buttonMargin: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}))

const ViewAllUsers = () => {
  const classes = useStyles()

  const page = useSelector((state) => state.users.currentPageNumber)
  const loading = useSelector(
    (state) => state.users.results.pages[page].loading
  )
  const userColumnHeaders = useSelector((state) => state.users.columnDefinition)
  const rowsPerPage = useSelector((state) => state.users.rowsPerPage)
  const userIds = useSelector((state) => state.users.results.pages[page].users)
  const allUsers = useSelector((state) => state.users.allUsers)
  const users = userIds.map((id) => allUsers[id])

  const [searchValue] = useState('')

  const columns: GridColDef[] = [
    ...userColumnHeaders.map((col) => ({
      ...col,
      headerName: col.title,
      flex: 1,
    })),
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      renderHeader: () => <></>,
      renderCell: ({ row }) => (
        <>
          <Link href={`${API_URL}users/${row.login_name}`} target="_blank">
            <Button className={classes.buttonMargin} variant="contained">
              Change Role
            </Button>
          </Link>
          <Link href={`${API_URL}users/${row.login_name}`} target="_blank">
            <Button
              className={`${classes.buttonMargin} ${classes.deleteButton}`}
              variant="contained"
            >
              Delete User
            </Button>
          </Link>
        </>
      ),
    },
  ]

  const fetchUserColumnDefinition = useFetchUserColumnDefinition()
  const fetchAllUsers = useFetchAllUsers()

  useEffect(() => {
    fetchUserColumnDefinition()
  }, [])

  useEffect(() => {
    fetchAllUsers({ page, searchValue })
  }, [page])

  // TODO: Implement search
  // const onEnterSearchField = (event) => {
  //   if (event.key === 'Enter') {
  //     dispatch(useFetchAllUsers({ page, searchValue }))
  //   }
  // }

  return (
    <>
      <Box m={4}>
        <Box display="flex" mb={2}>
          <Box flexGrow={1}>
            <Typography variant="h3" component="h3">
              Users
            </Typography>
          </Box>
          <Link href={`${API_URL}users/create`} target="_blank">
            <Button variant="contained" color="primary">
              Add New User
            </Button>
          </Link>
        </Box>
        {/*<Box my={2}>*/}
        {/*  <TextField*/}
        {/*    fullWidth*/}
        {/*    label="Search Users"*/}
        {/*    variant="outlined"*/}
        {/*    value={searchValue}*/}
        {/*    onChange={(e) => setSearchValue(e.target.value)}*/}
        {/*    onKeyPress={onEnterSearchField}*/}
        {/*  />*/}
        {/*</Box>*/}
        <Paper className={classes.paper}>
          <div className={classes.tableWrapper}>
            <DataGrid
              autoHeight
              loading={loading}
              disableSelectionOnClick
              disableColumnFilter
              pageSize={rowsPerPage}
              rowCount={users.length}
              columns={columns}
              rows={users}
              getRowId={(row) => row.login_name}
              components={{
                LoadingOverlay: EnhancedDataGridLoadingOverlay,
              }}
            />
          </div>
        </Paper>
      </Box>
    </>
  )
}

export default ViewAllUsers
