import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Box, Grid, Paper, Typography, makeStyles } from '@material-ui/core'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { EnhancedDataGridToolbar } from 'src/components/EnhancedDataGridToolbar'
import { useDispatch, useSelector } from 'src/store'
import { useCreateNewCustomerForTransactions } from '../../../actions/transactions'
import LoadingButton from '../../../components/LoadingButton'
import { toggleSelectTransactionsInPage } from '../../../reducers/transactions'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(4),
  },
}))

const SelectedTransactions = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  const createNewCustomerForTransactions = useCreateNewCustomerForTransactions()

  const selectedTransactions = useSelector(
    (state) => state.transactions.selectedTransactions
  )
  const allTransactions = useSelector(
    (state) => state.transactions.allTransactions
  )
  const transactions = selectedTransactions.map((id) => allTransactions[id])
  const isCreatingNewCustomer = useSelector(
    (state) => state.customers.isCreatingNewCustomer
  )

  const columnDefinition = useSelector(
    (state) => state.transactions.columnDefinition
  )

  /** @type GridColDef[] */
  const columns = [
    ...columnDefinition.map((col) => ({
      ...col,
      headerName: col.title,
      flex: 1,
    })),
    // {
    //   field: 'expand',
    //   headerName: 'Expand',
    //   flex: 1 / 3,
    //   align: 'right',
    //   sortable: false,
    //   disableColumnMenu: true,
    //   renderHeader: () => <></>,
    //   renderCell: ({ row }) => (
    //     <IconButton size="small">
    //       <ExpandMoreIcon />
    //     </IconButton>
    //   ),
    // },
  ]

  const handleNewCustomerClick = () => {
    if (
      window.confirm(
        'Are you sure you want to create a new customer for these transactions?'
      )
    ) {
      createNewCustomerForTransactions({ selectedTransactions })
        .then((response) => {
          history.push(`/customer/${response.data.new_customer_id}`)
          alert('Successfully created a new customer.')
          dispatch(toggleSelectTransactionsInPage([]))
        })
        .catch(() => {
          toast.error('An error occured while creating the new customer.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          })
        })
    }
  }

  return (
    <>
      <Grid container justifyContent="space-between" direction="row">
        <Typography variant="h2" component="h2" gutterBottom>
          Move Records
        </Typography>
        <LoadingButton
          loading={isCreatingNewCustomer}
          loadingText="Creating new customer..."
          defaultText="New Customer"
          onClick={handleNewCustomerClick}
        />
      </Grid>
      <Box mt={2}>
        <Paper className={classes.paper}>
          <DataGrid
            autoHeight
            disableSelectionOnClick
            disableColumnFilter
            rowCount={transactions.length}
            getRowId={(row) => row.transactionid}
            columns={columns}
            rows={transactions}
            components={{
              Toolbar: () => (
                <EnhancedDataGridToolbar
                  name="Selected Transactions"
                  disableAllButtons
                />
              ),
            }}
          />
          {/* <Table>
            <EnhancedTableHead
              rowCount={transactions.length}
              headCells={transactionsHeadCells}
            />
            <TableBody>
              {transactions.map((row, index) => {
                return (
                  <TransactionTableRow
                    key={index}
                    headCells={transactionsHeadCells}
                    row={row}
                  />
                )
              })}
            </TableBody>
          </Table> */}
        </Paper>
      </Box>
    </>
  )
}

export default SelectedTransactions
