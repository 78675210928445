import { KeyboardEvent, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import Search from '@material-ui/icons/Search'
import { clone } from 'lodash-es'
import { useResetAllSelections } from 'src/actions'
import { useFetchCustomerColumnDefinition } from 'src/actions/customers'
import { AppliedFilter } from 'src/constants'
import { useDispatch, useSelector } from 'src/store'
import FilterSection from '../../components/FilterSection'
import {
  setAppliedFilters,
  setSearchValue as setReduxSearchValue,
} from '../../reducers/customers'
import GoldenCustomers from './components/GoldenCustomers'

const MainPage = () => {
  const dispatch = useDispatch()

  const fetchCustomerColumnDefinition = useFetchCustomerColumnDefinition()
  const resetAllSelections = useResetAllSelections()

  const [searchValue, setSearchValue] = useState('')

  const reduxSearchValue = useSelector((state) => state.customers.searchValue)
  const appliedFilters = useSelector((state) => state.customers.appliedFilters)
  const columnDefinition = useSelector(
    (state) => state.customers.columnDefinition
  )
  const filterFields = columnDefinition.map((col) => col.title)

  const applyFilters = (filters: AppliedFilter[]) => {
    if (filters.length > 0) {
      let isValid = true
      filters.forEach((filter) => {
        if (filter.id === '') {
          isValid = false
        }
      })

      if (isValid) {
        dispatch(setAppliedFilters(clone(filters)))
        return
      }
    }

    toast.error('Missing inputs in filter box.', {
      position: toast.POSITION.BOTTOM_RIGHT,
    })
  }

  const onKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      dispatch(setReduxSearchValue(searchValue))
    }
  }

  useEffect(() => {
    setSearchValue(reduxSearchValue)
    resetAllSelections()
  }, [])

  useEffect(() => {
    fetchCustomerColumnDefinition()
  }, [fetchCustomerColumnDefinition])

  return (
    <>
      <Grid container>
        <Grid item md={4} xs={12}>
          <Box p={4}>
            <TextField
              placeholder="Search customers"
              variant="outlined"
              value={searchValue}
              onChange={(event) => setSearchValue(event.target.value)}
              onKeyDown={onKeyPress}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
            <FilterSection
              applyFilters={applyFilters}
              fields={filterFields}
              appliedFilters={appliedFilters}
            />
          </Box>
        </Grid>
        <Grid item md={8} xs={12}>
          <GoldenCustomers />
        </Grid>
      </Grid>
    </>
  )
}

export default MainPage
