import { combineReducers } from 'redux'
import authentication from './authentication'
import customers from './customers'
import logs from './logs'
import transactions from './transactions'
import users from './users'

const rootReducer = combineReducers({
  authentication,
  customers,
  logs,
  transactions,
  users,
})

export default rootReducer
