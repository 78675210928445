import { useEffect } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import ScheduleIcon from '@material-ui/icons/Schedule'
import { useFetchGoldenCustomer } from 'src/actions/customers'
import { useSelector } from 'src/store'

interface CustomerProfileProps {
  customerID: string
}

const CustomerProfile = ({ customerID }: CustomerProfileProps) => {
  const loading = useSelector((state) => state.customers.customer.loading)
  const allCustomers = useSelector((state) => state.customers.allCustomers)
  const customerDetails = allCustomers[customerID]

  const fetchGoldenCustomer = useFetchGoldenCustomer()

  useEffect(() => {
    fetchGoldenCustomer(customerID)
  }, [fetchGoldenCustomer, customerID])

  const displayData = (data: any) => {
    if (data === null) {
      return '-'
    }

    return data
  }

  return (
    <>
      {customerDetails && !loading && (
        <>
          <Typography variant="h4" component="h4" gutterBottom>
            {customerDetails.firstname}&nbsp;
            {customerDetails.lastname}&nbsp;
            {!!customerDetails.pending_merge && (
              <Tooltip title="Pending Recomputations">
                <ScheduleIcon color="error" />
              </Tooltip>
            )}
          </Typography>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>SCV ID</TableCell>
                <TableCell>{customerDetails.guid}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Membership ID</TableCell>
                <TableCell>{customerDetails.membershipid}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Date of Birth</TableCell>
                <TableCell>{displayData(customerDetails.dobdate)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Email Address</TableCell>
                <TableCell>{displayData(customerDetails.email)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Confidence Score</TableCell>
                <TableCell>
                  {displayData(customerDetails.confidencescore)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </>
      )}
    </>
  )
}

export default CustomerProfile
