import { Box, CircularProgress, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  box: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

const FullPageLoading = () => {
  const styles = useStyles()

  return (
    <Box className={styles.box}>
      <CircularProgress size="5em" color="primary" />
    </Box>
  )
}

export default FullPageLoading
