import { GridSortDirection } from '@mui/x-data-grid'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  AppliedFilter,
  ColumnDefinition,
  TransactionResponse,
} from 'src/constants'

interface TransactionsState {
  columnDefinition: ColumnDefinition[]
  searchValue: string
  currentPageNumber: number
  rowsPerPage: number
  numberOfTransactionsDisplayed: number
  order: GridSortDirection
  orderBy: string
  appliedFilters: AppliedFilter[]
  selectedTransactions: number[]
  results: {
    pages: Record<number, { loading: boolean; transactions: number[] }>
  }
  allTransactions: Record<number, TransactionResponse>
  error: string
}

const initialState: TransactionsState = {
  columnDefinition: [],
  searchValue: '',
  currentPageNumber: 0,
  rowsPerPage: 25,
  numberOfTransactionsDisplayed: 0,
  order: 'desc',
  orderBy: 'transactiondate',
  appliedFilters: [],
  selectedTransactions: [],
  results: {
    pages: {
      0: {
        loading: true,
        transactions: [],
      },
    },
  },
  allTransactions: {},
  error: '',
}

const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    setSearchValue: (
      state: TransactionsState,
      action: PayloadAction<string>
    ) => {
      state.searchValue = action.payload
    },
    setCurrentPageNumber: (
      state: TransactionsState,
      action: PayloadAction<number>
    ) => {
      const pageNumber = action.payload

      state.currentPageNumber = pageNumber
      Object.assign(state.results.pages, {
        [pageNumber]: {
          loading: true,
          transactions: [],
        },
      })
    },
    setRowsPerPage: (
      state: TransactionsState,
      action: PayloadAction<number>
    ) => {
      state.currentPageNumber = 0
      state.rowsPerPage = action.payload
    },
    setOrdering: (
      state: TransactionsState,
      action: PayloadAction<{ order: GridSortDirection; orderBy: string }>
    ) => {
      const isAsc =
        state.orderBy === action.payload.orderBy && state.order === 'asc'
      const order = isAsc ? 'desc' : 'asc'

      state.orderBy = action.payload.orderBy
      state.order = order
    },
    setAppliedFilters: (
      state: TransactionsState,
      action: PayloadAction<AppliedFilter[]>
    ) => {
      state.appliedFilters = action.payload
    },
    toggleSelectTransactionsInPage: (
      state: TransactionsState,
      action: PayloadAction<number[]>
    ) => {
      state.selectedTransactions =
        action.payload.length > 0 ? action.payload : []
    },
    fetchTransactionsRequest: (
      state: TransactionsState,
      action: PayloadAction<number>
    ) => {
      const pageNumber = action.payload

      state.currentPageNumber = pageNumber
      Object.assign(state.results.pages, {
        [pageNumber]: {
          loading: true,
          transactions: [],
        },
      })
    },
    fetchTransactionsResponse: (
      state: TransactionsState,
      action: PayloadAction<{
        data: TransactionResponse[]
        total_number_of_transactions: number
      }>
    ) => {
      const pageNumber = state.currentPageNumber

      const transactionIds = action.payload.data.map((t) => t.transactionid)
      const fetchedTransactions = action.payload.data.reduce(
        (acc, transaction) => {
          acc[transaction.transactionid] = transaction
          return acc
        },
        {} as Record<number, TransactionResponse>
      )

      const allTransactions = {
        ...state.allTransactions,
        ...fetchedTransactions,
      }

      state.numberOfTransactionsDisplayed =
        action.payload.total_number_of_transactions
      Object.assign(state.results.pages, {
        [pageNumber]: {
          loading: false,
          transactions: transactionIds,
        },
      })
      state.allTransactions = allTransactions
    },
    setTransactionsColumnDefinition: (
      state: TransactionsState,
      action: PayloadAction<ColumnDefinition[]>
    ) => {
      state.columnDefinition = action.payload
    },
    resetSearchAndFilters: (state: TransactionsState) => {
      Object.assign(state, {
        searchValue: '',
        appliedFilters: [],
      })
    },
    resetTransactionSelection: (state: TransactionsState) => {
      state.selectedTransactions = []
    },
  },
})

export const {
  fetchTransactionsResponse,
  fetchTransactionsRequest,
  setAppliedFilters,
  setTransactionsColumnDefinition,
  resetSearchAndFilters,
  toggleSelectTransactionsInPage,
  setOrdering,
  setSearchValue,
  setRowsPerPage,
  setCurrentPageNumber,
  resetTransactionSelection,
} = transactionsSlice.actions

export default transactionsSlice.reducer
