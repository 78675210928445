import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface AuthenticationState {
  user: any
  authLoading: boolean
}

const initialState: AuthenticationState = {
  user: null,
  authLoading: true,
}

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    loginResponse: (
      state: AuthenticationState,
      action: PayloadAction<AuthenticationState>
    ) => {
      state.user = action.payload
      state.authLoading = false
    },
    logoutResponse: (state: AuthenticationState) => {
      state.user = null
      state.authLoading = false
    },
    updateAuthLoading: (
      state: AuthenticationState,
      action: PayloadAction<boolean>
    ) => {
      state.authLoading = action.payload
    },
  },
})

export const { loginResponse, logoutResponse, updateAuthLoading } =
  authenticationSlice.actions

export default authenticationSlice.reducer
