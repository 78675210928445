import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { IconButton } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import { ChevronRight } from '@material-ui/icons'
import {
  DataGrid,
  GridRowData,
  GridSelectionModel,
  GridSortModel,
} from '@mui/x-data-grid'
import { GridColDef } from '@mui/x-data-grid'
import { orderBy as sort } from 'lodash-es'
import { browserDownload } from 'src/actions/constants'
import { useFetchGoldenCustomers } from 'src/actions/customers'
import { EnhancedDataGridToolbar } from 'src/components/EnhancedDataGridToolbar'
import LoadingButton from 'src/components/LoadingButton'
import {
  setCurrentPageNumber,
  setForMerging,
  setOrdering,
  setRowsPerPage,
  setSelectedCustomers,
} from 'src/reducers/customers'
import { useDispatch, useSelector } from 'src/store'
import EnhancedDataGridLoadingOverlay from '../../../components/EnhancedDataGridLoadingOverlay'
import * as constants from '../../../constants'
import { resetSearchAndFilters as resetTransactionsSearchAndFilters } from '../../../reducers/transactions'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  nextIcon: {
    cursor: 'pointer',
    color: theme.palette.secondary.dark,
  },
}))

const GoldenCustomers = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  const fetchGoldenCustomers = useFetchGoldenCustomers()

  const user = useSelector((state) => state.authentication.user)
  const page = useSelector((state) => state.customers.currentPageNumber)
  const appliedFilters = useSelector((state) => state.customers.appliedFilters)
  const loading = useSelector(
    (state) => state.customers.results.pages[page].loading
  )
  const forMerging = useSelector((state) => state.customers.forMerging)
  const { type } = useSelector((state) => state.customers.modal)
  const order = useSelector((state) => state.customers.order)
  const orderBy = useSelector((state) => state.customers.orderBy)
  const sortModel = [{ field: orderBy, sort: order }]
  const columnDefinition = useSelector(
    (state) => state.customers.columnDefinition
  )
  const rowsPerPage = useSelector((state) => state.customers.rowsPerPage)
  const searchValue = useSelector((state) => state.customers.searchValue)
  const selectedCustomers = useSelector(
    (state) => state.customers.selectedCustomers
  )
  const totalNumberOfCustomers = useSelector(
    (state) => state.customers.numberOfGoldenCustomersDisplayed
  )
  const customerIds = useSelector(
    (state) => state.customers.results.pages[page].customers
  )
  const allCustomers = useSelector((state) => state.customers.allCustomers)
  const goldenCustomers = customerIds.map((id) => allCustomers[id])
  const areCustomersMerging = useSelector(
    (state) => state.customers.areCustomersMerging
  )

  const [downloadingSelected, setDownloadingSelected] = useState(false)
  const [downloadingFiltered, setDownloadingFiltered] = useState(false)

  const columns: GridColDef[] = [
    ...columnDefinition.map((col) => ({
      ...col,
      headerName: col.title,
      flex: 1,
      hide: col.field === 'uuid',
      sortable: true,
      valueFormatter: ({ value }: GridRowData) =>
        value == null
          ? '-'
          : col.field === 'confidencescore'
          ? value.toFixed(2)
          : value,
    })),
    {
      field: 'actions',
      headerName: 'View Transactions',
      flex: 1 / 3,
      align: 'right',
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => <></>,
      renderCell: ({ row }) => (
        <IconButton
          onClick={() => handleCustomerClick(row.uuid)}
          size="small"
          edge="end"
        >
          <ChevronRight />
        </IconButton>
      ),
    },
  ]

  const handleDownloadSelectedCustomers = () => {
    setDownloadingSelected(true)

    if (selectedCustomers.length === 0) {
      return toast.error('No records selected.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    }

    const params = new URLSearchParams()
    selectedCustomers.forEach((selectedCustomer) =>
      params.append('selectedCustomers', selectedCustomer)
    )

    const url = `/customers/download_selected?` + params.toString()
    browserDownload(url, 'customers.csv')
      .catch(() => {
        toast.error('An error occurred while downloading customers.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
      })
      .finally(() => setDownloadingSelected(false))
  }

  const handleDownloadAllFilteredCustomers = () => {
    setDownloadingFiltered(true)

    if (appliedFilters.length === 0) {
      return toast.error('No filters applied.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    }

    const params = new URLSearchParams()
    appliedFilters.forEach((filter) => {
      params.append('appliedFilters', JSON.stringify(filter))
    })
    params.append('searchValue', searchValue)
    params.append('order', order!)
    params.append('orderBy', orderBy)

    const url = `/customers/download_filtered?${params.toString()}`
    browserDownload(url, 'customers.csv')
      .catch(() => {
        toast.error('An error occurred while downloading customers.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
      })
      .finally(() => setDownloadingFiltered(false))
  }

  const handleMergeRecords = () => {
    if (forMerging.length < 2 && selectedCustomers.length < 2) {
      return toast.error('At least 2 records must be selected.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    }
    const selectedForMerging = selectedCustomers.map((id) => allCustomers[id])

    const checkAlreadyMerging = selectedForMerging.filter(
      ({ pending_merge }) => pending_merge?.action === constants.MERGE_ACTION
    )
    if (checkAlreadyMerging.length > 0) {
      return toast.error('There are selected customers already merging', {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    }
    if (type === 'confirmation') {
      dispatch(
        setForMerging(sort(selectedForMerging, 'confidencescore', 'desc'))
      )
    }
    history.push(`/merge-list`)
  }

  const handleCustomerClick = (id: number) => {
    dispatch(resetTransactionsSearchAndFilters())
    history.push(`/customer/${id}`)
  }

  const handleSortModelChange = (model: GridSortModel) => {
    if (model.length === 0) return
    if (model[0].sort !== order || model[0].field !== orderBy) {
      dispatch(
        setOrdering({
          order: model[0].sort,
          orderBy: model[0].field,
        })
      )
    }
  }

  const handlePageSizeChange = (newPageSize: number) => {
    dispatch(setRowsPerPage(newPageSize))
  }

  const handleSelectionModelChange = (selectionModel: GridSelectionModel) => {
    dispatch(setSelectedCustomers(selectionModel as string[]))
  }

  const handlePageChange = (newPage: number) => {
    dispatch(setCurrentPageNumber(newPage))
  }

  useEffect(() => {
    fetchGoldenCustomers({
      searchValue,
      pageIndex: page,
      pageSize: rowsPerPage,
      appliedFilters,
      order,
      orderBy,
    })
  }, [
    fetchGoldenCustomers,
    appliedFilters,
    page,
    order,
    orderBy,
    rowsPerPage,
    searchValue,
  ])

  return (
    <>
      <Box mr={4} mt={4}>
        <Grid
          container
          alignItems="flex-start"
          justifyContent="flex-end"
          direction="row"
        >
          <LoadingButton
            loading={downloadingFiltered}
            loadingText="Downloading..."
            defaultText="Download Filtered Customers"
            variant="contained"
            onClick={() => handleDownloadAllFilteredCustomers()}
          />
          <Box ml={3}>
            <LoadingButton
              loading={downloadingSelected}
              loadingText="Downloading..."
              defaultText="Download Selected Customers"
              variant="contained"
              onClick={() => handleDownloadSelectedCustomers()}
            />
          </Box>
          {constants.atLeastEditorRole.includes(user.role) && (
            <Box ml={3}>
              <LoadingButton
                loading={areCustomersMerging}
                loadingText="Merging customers..."
                defaultText="Merge Customers"
                onClick={handleMergeRecords}
              />
            </Box>
          )}
        </Grid>
      </Box>

      <Box m={4}>
        <Paper className={classes.paper}>
          <div className={classes.tableWrapper}>
            <DataGrid
              autoHeight
              checkboxSelection
              disableColumnMenu
              loading={loading}
              pageSize={rowsPerPage}
              onPageSizeChange={handlePageSizeChange}
              paginationMode="server"
              rowCount={totalNumberOfCustomers}
              page={page}
              onPageChange={handlePageChange}
              columns={columns}
              rows={goldenCustomers}
              getRowId={(row) => row.uuid}
              sortingMode="server"
              sortModel={sortModel}
              onSortModelChange={handleSortModelChange}
              selectionModel={selectedCustomers}
              onSelectionModelChange={handleSelectionModelChange}
              components={{
                Toolbar: EnhancedDataGridToolbar,
                LoadingOverlay: EnhancedDataGridLoadingOverlay,
              }}
              componentsProps={{
                toolbar: {
                  name: 'Golden Customers',
                  numberSelected: selectedCustomers.length,
                },
              }}
            />
          </div>
        </Paper>
      </Box>
    </>
  )
}

export default GoldenCustomers
