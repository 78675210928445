import { toast } from 'react-toastify'
import { SearchParams } from 'src/constants'
import {
  fetchAllLogsRequest,
  fetchAllLogsResponse,
  setLogsColumnDefinition,
} from '../reducers/logs'
import { useDispatch } from '../store'
import { getAxios } from './constants'

export const useFetchLogColumnDefinition = () => {
  const dispatch = useDispatch()

  return () => {
    const url = '/changelogs/columns'

    getAxios()
      .get(url)
      .then((response) => {
        dispatch(setLogsColumnDefinition(response.data))
      })
  }
}

export const useFetchAllLogs = () => {
  const dispatch = useDispatch()

  return (params: SearchParams) => {
    dispatch(fetchAllLogsRequest(params.pageIndex))

    const url = `/changelogs`

    getAxios()
      .post(url, params)
      .then((response) => {
        dispatch(fetchAllLogsResponse(response.data))
        return response
      })
      .catch((e) => {
        console.error(e.message)
        toast.error('An error occurred in fetching all logs.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
      })
  }
}
