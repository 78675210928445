import { Select as BaseSelect, makeStyles } from '@material-ui/core'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingRight: theme.spacing(2),
  },
  field: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  inputText: {
    fontSize: '0.875rem',
  },
  label: {
    fontSize: '0.875rem',
  },
}))

const Select = ({ name, label, value, handleChange, options }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <InputLabel htmlFor={name} className={classes.label}>
        {label}
      </InputLabel>
      <BaseSelect
        value={value}
        onChange={handleChange}
        inputProps={{
          name,
          id: name,
          classes: {
            root: classes.inputText,
          },
        }}
        className={classes.field}
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option}>
            {option}
          </MenuItem>
        ))}
      </BaseSelect>
    </div>
  )
}

export default Select
