import { useCallback } from 'react'
import { toast } from 'react-toastify'
import { SearchParams } from 'src/constants'
import {
  fetchCustomerRequest,
  fetchCustomerResponse,
  fetchGoldenCustomersRequest,
  fetchGoldenCustomersResponse,
  removePendingMerge,
  setCustomersColumnDefinition,
  toggleAreCustomersMerging,
} from '../reducers/customers'
import { useDispatch } from '../store'
import { getAxios } from './constants'

export const useFetchCustomerColumnDefinition = () => {
  const dispatch = useDispatch()

  return useCallback(() => {
    const url = '/customers/columns'
    getAxios()
      .get(url)
      .then((response) => {
        dispatch(setCustomersColumnDefinition(response.data))
      })
  }, [dispatch])
}

export const useFetchGoldenCustomers = () => {
  const dispatch = useDispatch()

  return useCallback(
    (params: SearchParams) => {
      dispatch(fetchGoldenCustomersRequest(params.pageIndex))

      const url = '/customers'

      getAxios()
        .post(url, params)
        .then((response) => {
          dispatch(fetchGoldenCustomersResponse(response.data))
          return response
        })
        .catch((err) => {
          console.error(err)
          toast.error('An error occurred in gathering golden customers.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          })
        })
    },
    [dispatch]
  )
}

export const useFetchGoldenCustomer = () => {
  const dispatch = useDispatch()

  return useCallback(
    (customerID: string) => {
      dispatch(fetchCustomerRequest())

      const url = `/customers/${customerID}`

      getAxios()
        .get(url)
        .then((response) => {
          dispatch(fetchCustomerResponse(response.data))
          return response
        })
        .catch(() => {
          toast.error('Customer not found.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          })
        })
    },
    [dispatch]
  )
}

export const useMergeSelectedCustomers = () => {
  const dispatch = useDispatch()

  return (selectedCustomers: string[]) => {
    dispatch(toggleAreCustomersMerging())

    const url = `/customers/merge`

    return getAxios()
      .post(url, { selectedCustomers })
      .then((response) => {
        dispatch(toggleAreCustomersMerging())
        return response
      })
  }
}

export const useUndoMergeCustomers = () => {
  const dispatch = useDispatch()

  return (params: { customer_id: string; transaction_id?: number }) => {
    const url = `/customers/unmerge`

    return getAxios()
      .post(url, params)
      .then((response) => {
        dispatch(removePendingMerge(params.customer_id))
        return response
      })
  }
}
