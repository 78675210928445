import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { InputAdornment, TextField } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { Search } from '@material-ui/icons'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { clone } from 'lodash-es'
import { useFetchTransactionColumnDefinition } from 'src/actions/transactions'
import { AppliedFilter } from 'src/constants'
import { setAppliedFilters } from 'src/reducers/transactions'
import CustomerProfile from 'src/scenes/ViewCustomer/components/CustomerProfile'
import Transactions from 'src/scenes/ViewCustomer/components/Transactions'
import { useDispatch, useSelector } from 'src/store'
import FilterSection from '../../components/FilterSection'

const ViewCustomer = () => {
  const dispatch = useDispatch()
  const params = useParams<{ id?: string }>()
  const [searchValue, setSearchValue] = useState('')

  const columnDefinition = useSelector(
    (state) => state.transactions.columnDefinition
  )
  const filterFields = columnDefinition.map((col) => col.title)

  const appliedFilters = useSelector(
    (state) => state.transactions.appliedFilters
  )

  const fetchTransactionColumnDefinition = useFetchTransactionColumnDefinition()

  const applyFilters = (filters: AppliedFilter[]) => {
    if (filters.length > 0) {
      let isValid = true
      filters.forEach((filter) => {
        if (filter.id === '') {
          isValid = false
        }
      })

      if (isValid) {
        dispatch(setAppliedFilters(clone(filters)))
        return
      }
    }

    toast.error('Missing inputs in filter box.', {
      position: toast.POSITION.BOTTOM_RIGHT,
    })
  }

  const customerID = params.id!

  // TODO: Implement search
  // const onKeyDown = (event) => {
  //   if (event.key === 'Enter') {
  //     dispatch(setReduxSearchValue(event.target.value))
  //   }
  // }

  useEffect(() => {
    fetchTransactionColumnDefinition()
  }, [fetchTransactionColumnDefinition])

  return (
    <>
      <Grid container>
        <Grid item md={8} xs={12}>
          <Box ml={4} mt={4}>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
              <Link to="/" color="inherit">
                Main
              </Link>
              <Typography color="textPrimary">Golden Record Details</Typography>
            </Breadcrumbs>
            <Box mt={3}>
              <CustomerProfile customerID={customerID} />
            </Box>
          </Box>
        </Grid>
        <Grid item md={4} xs={12}>
          <Box p={4}></Box>
          <Box p={4}>
            <TextField
              placeholder="Search transactions"
              variant="outlined"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
            <FilterSection
              fields={filterFields}
              applyFilters={applyFilters}
              appliedFilters={appliedFilters}
            />
          </Box>
        </Grid>
      </Grid>
      <Transactions customerID={customerID} />
    </>
  )
}

export default ViewCustomer
