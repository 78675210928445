import { MouseEvent, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  AppBar,
  Divider,
  List,
  ListItem,
  Link as MenuLink,
  Popover,
  Toolbar,
  Typography,
} from '@material-ui/core'
import { Theme, withStyles } from '@material-ui/core/styles'
import { ClassNameMap, Styles } from '@material-ui/core/styles/withStyles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import * as constants from 'src/constants'
import { useDispatch, useSelector } from 'src/store'
import { logout } from '../actions/authentication'

const styles = (theme: Theme) =>
  ({
    root: {
      flexGrow: 1,
    },
    headerFillerDiv: {
      flexGrow: 1,
    },
    titleText: {
      fontWeight: 700,
    },
    popoverText: {
      cursor: 'pointer',
    },
    banner: {
      cursor: 'pointer',
      fontWeight: 700,
      paddingRight: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    link: {
      textDecoration: 'none',
      color: 'inherit',
    },
  } as Styles<Theme, {}, never>)

interface HeaderProps {
  classes: ClassNameMap
}

const Header = ({ classes }: HeaderProps) => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.authentication.user)
  const [anchorElement, setAnchorElement] = useState<HTMLSpanElement | null>(
    null
  )

  const handleOpenHeaderMenu = (event: MouseEvent<HTMLSpanElement>) => {
    setAnchorElement(event.currentTarget)
  }

  const handleCloseHeaderMenu = () => {
    setAnchorElement(null)
  }

  const handleLogout = () => {
    handleCloseHeaderMenu()
    dispatch(logout())
  }

  return (
    <AppBar position="static" color="inherit" className={classes.root}>
      <Toolbar>
        <div>
          <Link to="/" className={classes.link}>
            <Typography
              variant="h5"
              color="primary"
              className={classes.titleText}
            >
              &nbsp;&nbsp;Single Customer View
            </Typography>
          </Link>
        </div>
        <div className={classes.headerFillerDiv}></div>
        {user !== null && (
          <>
            <div>
              <Typography
                variant="button"
                color="primary"
                className={classes.banner}
                onClick={handleOpenHeaderMenu}
              >
                Welcome, {user.login_name}
                <ExpandMoreIcon />
              </Typography>
              <Popover
                anchorEl={anchorElement}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElement)}
                onClose={handleCloseHeaderMenu}
                className={classes.menu}
              >
                <List>
                  {constants.atLeastAdministratorRole.includes(user.role) && (
                    <>
                      <ListItem>
                        <Typography className={classes.popoverText}>
                          <Link
                            to="/users"
                            className={classes.link}
                            onClick={handleCloseHeaderMenu}
                          >
                            View Users
                          </Link>
                        </Typography>
                      </ListItem>
                      <Divider variant="middle" />
                      <ListItem>
                        <Typography className={classes.popoverText}>
                          <Link
                            to="/logs"
                            className={classes.link}
                            onClick={handleCloseHeaderMenu}
                          >
                            View Logs
                          </Link>
                        </Typography>
                      </ListItem>
                      <Divider variant="middle" />
                    </>
                  )}
                  <Divider variant="middle" />
                  <ListItem>
                    <Typography className={classes.popoverText}>
                      <MenuLink color="inherit" onClick={handleLogout}>
                        Logout
                      </MenuLink>
                    </Typography>
                  </ListItem>
                </List>
              </Popover>
            </div>
          </>
        )}
      </Toolbar>
    </AppBar>
  )
}

export default withStyles(styles)(Header)
