import { useCallback } from 'react'
import { toast } from 'react-toastify'
import { SearchParams } from 'src/constants'
import {
  fetchCandidateCustomersRequest,
  fetchCandidateCustomersResponse,
  toggleIsCreatingNewCustomer,
  toggleIsMovingTransactionsToCustomer,
} from 'src/reducers/customers'
import {
  fetchTransactionsRequest,
  fetchTransactionsResponse,
  setTransactionsColumnDefinition,
} from '../reducers/transactions'
import { useDispatch } from '../store'
import { getAxios } from './constants'

export const useFetchTransactionColumnDefinition = () => {
  const dispatch = useDispatch()

  return useCallback(() => {
    const url = '/transactions/columns'
    getAxios()
      .get(url)
      .then((response) => {
        dispatch(setTransactionsColumnDefinition(response.data))
      })
  }, [dispatch])
}

export const useFetchTransactions = () => {
  const dispatch = useDispatch()

  return useCallback((params: SearchParams) => {
    dispatch(fetchTransactionsRequest(params.pageIndex))

    const url = `/customers/${params.customerID}/transactions`

    getAxios()
      .post(url, params)
      .then((response) => {
        dispatch(fetchTransactionsResponse(response.data))
      })
  }, [])
}

export const useFetchCandidateCustomers = () => {
  const dispatch = useDispatch()

  return useCallback(
    (params: { customerID: number; selectedTransactions: number[] }) => {
      dispatch(fetchCandidateCustomersRequest())

      const url = '/transactions/candidate-customers'

      getAxios()
        .post(url, params)
        .then((response) => {
          dispatch(fetchCandidateCustomersResponse(response.data))
          return response
        })
        .catch(() => {
          toast.error('An error occured fetching the candidate customers.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          })
        })
    },
    []
  )
}

export const useMoveTransactionsToCustomer = () => {
  const dispatch = useDispatch()

  return (params: {
    selectedCustomerID: number
    selectedTransactions: number[]
  }) => {
    dispatch(toggleIsMovingTransactionsToCustomer())

    const url = '/transactions/move'

    return getAxios()
      .post(url, params)
      .then((response) => {
        dispatch(toggleIsMovingTransactionsToCustomer())
        return response
      })
  }
}

export const useCreateNewCustomerForTransactions = () => {
  const dispatch = useDispatch()

  return (params: { selectedTransactions: number[] }) => {
    dispatch(toggleIsCreatingNewCustomer())

    const url = '/transactions/new-customer'

    return getAxios()
      .post(url, params)
      .then((response) => {
        dispatch(toggleIsCreatingNewCustomer())
        return response
      })
  }
}
