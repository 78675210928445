import { toast } from 'react-toastify'
import {
  fetchAllUsersRequest,
  fetchAllUsersResponse,
  setUsersColumnDefinition,
} from '../reducers/users'
import { useDispatch } from '../store'
import { getAxios } from './constants'

export const useFetchUserColumnDefinition = () => {
  const dispatch = useDispatch()

  return () => {
    const url = '/users/columns'
    getAxios()
      .get(url)
      .then((response) => {
        dispatch(setUsersColumnDefinition(response.data))
      })
  }
}

export const useFetchAllUsers = () => {
  const dispatch = useDispatch()

  return (params: { page: number; searchValue: string }) => {
    dispatch(fetchAllUsersRequest(params.page))

    const url = '/users'

    getAxios()
      .get(url)
      .then((response) => {
        dispatch(fetchAllUsersResponse(response.data))
        return response
      })
      .catch((e) => {
        console.error(e)
        toast.error('An error occurred in fetching all users.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
      })
  }
}
