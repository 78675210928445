import { Dispatch } from 'redux'
import {
  loginResponse,
  logoutResponse,
  updateAuthLoading,
} from '../reducers/authentication'
import { getAxios } from './constants'

export const login = () => {
  return (dispatch: Dispatch) => {
    return getAxios()
      .get('/users/me')
      .then((response) => dispatch(loginResponse(response.data)))
      .catch((e) => console.error(e.message))
      .finally(() => dispatch(updateAuthLoading(false)))
  }
}

export const logout = () => (dispatch: Dispatch) =>
  getAxios()
    .get('/auth/logout')
    .catch((e) => console.error(e.message))
    .finally(() => dispatch(logoutResponse()))
